@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,600;0,700;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  scroll-behavior: smooth;
  font-family: "Josefin Sans", sans-serif;
}

.container-main {
  background: url("../src/assets/img/registon.png") no-repeat center;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.521);
  /* position: absolute;
  top: 0; */
  /* filter: blur(2.5px); */
  /* opacity: 0.1; */
}

.flex-bg {
  background: url("../src/assets/img/map2.png") no-repeat;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 100%;
  background-position: top;
}
